// hero
import pan from '/images/hero/pan.webp'
import deksel from '/images/hero/deksel.webp'
import paprika1 from '/images/groente/paprika1.webp'
import paprika2 from '/images/groente/paprika2.webp'
import bimi from '/images/groente/bimi.webp'
import koriander from '/images/groente/koriander.webp'
import peper1 from '/images/groente/peper1.webp'
import peper2 from '/images/groente/peper2.webp'
import peper3 from '/images/groente/peper3.webp'
import peper4 from '/images/groente/peper4.webp'
import sperziebonen from '/images/groente/sperziebonen.webp'
import limoen from '/images/groente/limoen.webp'

// introductie
import introductionImage1 from '/images/introduction/image1.webp'
import introductionImage2 from '/images/introduction/image2.webp'
import introductionImage3 from '/images/introduction/image3.webp'
import introductionImage4 from '/images/introduction/image4.webp'
import introductionImage5 from '/images/introduction/image5.webp'
import introductionImage6 from '/images/introduction/image6.webp'
import introductionImage7 from '/images/introduction/image7.webp'
import introductionPeper from '/images/introduction/peper.webp'
import introductionBabyMais from '/images/introduction/babymais.webp'

// timeline
import cookingPan from './images/timeline/cookingPan.webp'
import cookingPanShadow from './images/timeline/cookingPanShadow.webp'
import fire from './images/timeline/fire.webp'
import harbour from './images/timeline/harbour.webp'

// profit
import cuttingBoardMobile from '/images/profit/cuttingboardmobile.webp'
import cuttingBoardDesktop from '/images/profit/cuttingboarddesktop.webp'
import profit1 from '/images/profit/profitfullratio-1.svg'
import profit2 from '/images/profit/profitfullratio-2.svg'
import cloth from '/images/profit/cloth.webp'
import mes from '/images/profit/mes.png'

// payoff
import payoffInnovaties from '/images/payoff/payoff-innovaties.webp'
import payoffSmaakchefs from '/images/payoff/payoff-smaakchefs.webp'
import payoffSlimMenu from '/images/payoff/payoff-slim-menu.webp'

// impact
import ball1 from '/images/impact/bolletje1.webp'
import ball2 from '/images/impact/bolletje2.webp'
import ball3 from '/images/impact/bolletje3.webp'
import ball4 from '/images/impact/bolletje4.webp'
import ball5 from '/images/impact/bolletje5.webp'
import ball6 from '/images/impact/bolletje6.webp'
import ball7 from '/images/impact/bolletje7.webp'
import ball8 from '/images/impact/bolletje8.webp'
import ball9 from '/images/impact/bolletje9.webp'
import ball10 from '/images/impact/bolletje10.webp'
import ball11 from '/images/impact/bolletje11.webp'
import ball12 from '/images/impact/bolletje12.webp'
import ball13 from '/images/impact/bolletje13.webp'
import ball14 from '/images/impact/bolletje14.webp'
import ball15 from '/images/impact/bolletje15.webp'
import ball16 from '/images/impact/bolletje16.webp'
import ball17 from '/images/impact/bolletje17.webp'
import ball18 from '/images/impact/bolletje18.webp'
import ball19 from '/images/impact/bolletje19.webp'
import ball20 from '/images/impact/bolletje20.webp'
import ball21 from '/images/impact/bolletje21.webp'
import ball22 from '/images/impact/bolletje22.webp'
import ball23 from '/images/impact/bolletje23.webp'
import ball24 from '/images/impact/bolletje24.webp'
import ball25 from '/images/impact/bolletje25.webp'
import ball26 from '/images/impact/bolletje26.webp'
import ball27 from '/images/impact/bolletje27.webp'

// recipes
import broodjeHummus from './images/recipes/broodje-hummus.webp'
import smoothie from './images/recipes/smoothie.webp'
import jackfruit from './images/recipes/jackfruit.webp'

// spotlight-story
import hartje1 from '/images/spotlight/hartje1.webp'
import hartje2 from '/images/spotlight/hartje2.webp'
import hartje3 from '/images/spotlight/hartje3.webp'
import hartje4 from '/images/spotlight/hartje4.webp'
import hartje5 from '/images/spotlight/hartje5.webp'
import hartje6 from '/images/spotlight/hartje6.webp'
import hartje7 from '/images/spotlight/hartje7.webp'
import hartje8 from '/images/spotlight/hartje8.webp'
import hartje9 from '/images/spotlight/hartje9.webp'
import hartje10 from '/images/spotlight/hartje10.webp'
import hartje11 from '/images/spotlight/hartje11.webp'
import hartje12 from '/images/spotlight/hartje12.webp'
import hartje13 from '/images/spotlight/hartje13.webp'
import hartje14 from '/images/spotlight/hartje14.webp'
import hartje15 from '/images/spotlight/hartje15.webp'
import hartje16 from '/images/spotlight/hartje16.webp'
import hartje17 from '/images/spotlight/hartje17.webp'
import hartje18 from '/images/spotlight/hartje18.webp'
import hartje19 from '/images/spotlight/hartje19.webp'
import hartje20 from '/images/spotlight/hartje20.webp'
import hartje21 from '/images/spotlight/hartje21.webp'
import hartje22 from '/images/spotlight/hartje22.webp'
import hartje23 from '/images/spotlight/hartje23.webp'
import hartje24 from '/images/spotlight/hartje24.webp'
import hartje25 from '/images/spotlight/hartje25.webp'

// story
import food from '/images/story/food.webp'

// error
import panError from '/images/error/pan.webp'

const peopleImages = [
  hartje1,
  hartje2,
  hartje3,
  hartje4,
  hartje5,
  hartje6,
  hartje7,
  hartje8,
  hartje9,
  hartje10,
  hartje11,
  hartje12,
  hartje13,
  hartje14,
  hartje15,
  hartje16,
  hartje17,
  hartje18,
  hartje19,
  hartje20,
  hartje21,
  hartje22,
  hartje23,
  hartje24,
  hartje25,
]

const ballImages = [
  ball1,
  ball2,
  ball3,
  ball4,
  ball5,
  ball6,
  ball7,
  ball8,
  ball9,
  ball10,
  ball11,
  ball12,
  ball13,
  ball14,
  ball15,
  ball16,
  ball17,
  ball18,
  ball19,
  ball20,
  ball21,
  ball22,
  ball23,
  ball24,
  ball25,
  ball26,
  ball27,
]

export const images = {
  // hero
  pan,
  deksel,
  paprika1,
  paprika2,
  bimi,
  koriander,
  peper1,
  peper2,
  peper3,
  peper4,
  sperziebonen,
  limoen,

  //introduction
  introductionImage1,
  introductionImage2,
  introductionImage3,
  introductionImage4,
  introductionImage5,
  introductionImage6,
  introductionImage7,
  introductionPeper,
  introductionBabyMais,
  food,

  // timeline
  cookingPan,
  cookingPanShadow,
  fire,
  harbour,

  // profit
  cuttingBoardMobile,
  cuttingBoardDesktop,
  profit1,
  profit2,
  cloth,
  mes,

  // payoff
  payoffInnovaties,
  payoffSmaakchefs,
  payoffSlimMenu,

  // impact
  ballImages,

  // spotlight-story
  peopleImages,

  // recipes
  broodjeHummus,
  smoothie,
  jackfruit,

  //error
  panError
}
